<div class="enterprise_user--sidebar" *ngIf="currentRoute != '/pages/enterprise-files'">
    <div id="mySidenav" [@inOutAnimation]="!nav ? 'open' : 'closed'" class="sidenav flex-column">
        <div class="trance-icon">
            <div class="logo--div flex colgap-4">
                   <span><a href="#"><img src="../../../../assets/images/trance.svg" class="Trance" /></a></span>
            <span class="four-pointzero">4.4</span>
            </div>         
            <span class="toggle_arrow"> <a href="javascript:void(0)" class="closebtn t-flex" (click)="closeNav()">
                    <!-- <img src="../../../../assets/images/back.svg" class="back"> -->
                    <mat-icon class="back flex-c" svgIcon="back" aria-hidden="false" aria-label="back"></mat-icon>
                </a></span>
        </div>
        <div [class.active]="currentRoute.includes('/pages/dashboard') && !notification && !showUserMenu"
            *ngIf="appCommonService.isPermissionsExists(appCommonService.dashboardPermissions)"
            class="sidehomediv cur-point  " routerLink="/pages/dashboard" (click)="showUserMenu=false;">
            <span> <a>
                    <mat-icon class="home" svgIcon="home" aria-hidden="false" aria-label="home"></mat-icon>
                </a></span>
            <span class="sidehometext">Home</span>
        </div>
        <div [class.active]="currentRoute.includes('/pages/pma-projects') && !notification && !showUserMenu"
            *ngIf="!appCommonService.isSuperAdmin()&&appCommonService.isPermissionsExists(appCommonService.pmaProjects)"
            class="sideprojectdiv cur-point" routerLink="/pages/pma-projects" (click)="showUserMenu=false;">
            <span><a>
                    <mat-icon class="side-project" svgIcon="side-project" aria-hidden="false"
                        aria-label="side-project"></mat-icon>
                </a></span>
            <span class="sideaccounttext">Projects</span>
        </div>
        <div [class.active]="currentRoute.includes('/pages/admin-accounts') && !notification && !showUserMenu"
            *ngIf="appCommonService.isSuperAdmin() && appCommonService.isPermissionsExists(appCommonService.accountPermissions)"
            class="sideaccountdiv cur-point" routerLink="/pages/admin-accounts" (click)="showUserMenu=false;">
            <span><a>
                    <mat-icon class="account" svgIcon="account" aria-hidden="false" aria-label="account"></mat-icon>
                </a></span>
            <span class="sideaccounttext">Accounts</span>
        </div>
        <div [class.active]="currentRoute.includes('/pages/admin-manage') && !notification && !showUserMenu"
            class="sidemanagediv cur-point" routerLink="/pages/admin-manage"
            *ngIf="appCommonService.isSuperAdmin() &&appCommonService.isPermissionsExists(appCommonService.adminManagePermissions)" (click)="showUserMenu=false;">
            <span><a>
                    <mat-icon class="manage" svgIcon="manage" aria-hidden="false" aria-label="manage"></mat-icon>
                </a></span>
            <span class="sidemanagetext">Manage</span>
        </div>
        <div [class.active]="currentRoute.includes('/pages/service-pricing') && !notification && !showUserMenu"
            *ngIf="appCommonService.isSuperAdmin() && appCommonService.isPermissionsExists(appCommonService.servicePricingPermissions)"
            class="sidepricingdiv cur-point" routerLink="/pages/service-pricing" (click)="showUserMenu=false;">
            <span> <a>
                    <mat-icon class="pricing" svgIcon="pricing" aria-hidden="false" aria-label="pricing"></mat-icon>
                </a></span>
            <span class="sidepricingtext">Pricing</span>
        </div>
        <div [class.active]="currentRoute.includes('/pages/billing') && !notification && !showUserMenu"
            *ngIf="!appCommonService.isSuperAdmin() && appCommonService.isPermissionsExists(appCommonService.billingPermissions)"
            class="sidepricingdiv cur-point" routerLink="/pages/billing" (click)="showUserMenu=false;">
            <span> <a>
                    <mat-icon class="pricing" svgIcon="pricing" aria-hidden="false" aria-label="pricing"></mat-icon>
                </a></span>
            <span class="sidepricingtext">Billing</span>
        </div>
        <div *ngIf="appCommonService.isPermissionsExists(appCommonService.filesMenuPermissions)"
            [class.active]="currentRoute.includes('/pages/files/') && !notification && !showUserMenu" class="sidevideo-filediv cur-point"
            routerLink="/pages/files/" (click)="showUserMenu=false;onFilesClick()">
            <span><a>
                    <mat-icon class="video-file" svgIcon="video-file" aria-hidden="false"
                        aria-label="video-file"></mat-icon>
                </a></span>
            <span class="sidevideo-filetext">Files</span>
        </div>
        <div [class.active]="currentRoute.includes('/pages/help') && !notification && !showUserMenu"
            *ngIf="!appCommonService.isSuperAdmin()" class="side-help cur-point" routerLink="/pages/help" (click)="showUserMenu=false;">
            <span><a>
                    <mat-icon class="side-help" svgIcon="side-help" aria-hidden="false"
                        aria-label="side-help"></mat-icon>
                </a></span>
            <span class="side-help">Help</span>
        </div>

        <div [class.active]="currentRoute.includes('/pages/settings/admin-settings') && !notification && !showUserMenu"
            class="sidesettingsdiv" routerLink="/pages/settings/admin-settings" (click)="showUserMenu=false;">
            <span><a>
                    <mat-icon class="settings" svgIcon="settings" aria-hidden="false" aria-label="settings"></mat-icon>
                </a></span>
            <span class="sidesettingstext">Settings</span>
        </div>
        <div [class.active]="notification && !showUserMenu" class="sidenotificationsdiv cur-point" (click)="bell($event)"
            *ngIf="appCommonService.isPermissionsExists(appCommonService.notifications)">
            <span><a>
                    <mat-icon class="bell mt-auto" svgIcon="bell" aria-hidden="false" aria-label="bell"></mat-icon>
                </a></span>
            <span class="sidenotificationstext">Notifications</span>
        </div>

        <div class="sidetheme-switchdiv theme-toggle cur-point" (click)="changeTheme()">
            <span> <a (click)="changeTheme()">
                    <mat-icon class="theme-switch" svgIcon="theme-switch" aria-hidden="false" aria-label="theme-switch">
                    </mat-icon>
                </a></span>
            <span class="sidetheme-switchtext">View in {{isDarkMode? "Light Theme" : "Dark Theme"}}</span>
        </div>
        <!-- <div class="sideaccount1div flex-column cur-point" (click)="openUserMenu($event)" [class.open]="showUserMenu">
            
            <div class="user-div flex-c">
                <span><a>
                        <mat-icon class="user-white" svgIcon="user-white" aria-hidden="false"
                            aria-label="user-white"></mat-icon>
                    </a></span>
                <span class="sideaccount1text">langulage</span>
            </div>
            <div class="flex-c cur-point" *ngFor="let item of langulages">
                <span></span>
                <span class="pwdtext" (click)="onSelectLang(item.value)">{{item.name}}</span>
            </div>
        </div> -->
        <div class="sideaccount1div flex-column cur-point" (click)="openUserMenu($event)" [class.open]="showUserMenu">
            <!-- add active class to above div to open dropdown -->
            <div [class.active]="showUserMenu && !notification" class="side user-div flex-c">
                <span><a>
                        <mat-icon class="user-white" svgIcon="user-white" aria-hidden="false"
                            aria-label="user-white"></mat-icon>
                    </a></span>
                <span class="sideaccount1text" [title]="userName">{{userName}}</span>
            </div>
            <div class="pwd flex-c cur-point">
                <span><a>
                        <mat-icon class="password" svgIcon="password" aria-hidden="false"
                            aria-label="password"></mat-icon>
                    </a></span>
                <span class="pwdtext" (click)="openChangePasswordModal()">Change Password</span>
            </div>
            <div class="exit flex-c cur-point" (click)="logout()">
                <span><a>
                        <mat-icon class="exit" svgIcon="exit" aria-hidden="false" aria-label="exit"></mat-icon>
                    </a></span>
                <span class="exittext">Log Out</span>
            </div>
        </div>
        <div class="sidelangdiv flex-column cur-point" (click)="onlanguage($event);isActive=!isActive">
            <div class="lang--div colgap-8  flex-c">
                <!-- <span><a>
                        <mat-icon class="global-language" svgIcon="global-language" aria-hidden="false"
                            aria-label="global-language"></mat-icon>
                    </a></span>
                <span class="side-lang">Select Language</span> -->
            </div>
        </div>
    </div>
    <div class="lang__span--section " [ngClass]="isActive?'active':'in-active'">
        <div class="search__field--div">
            <mat-form-field class="cust-form-group" appearance="fill">
                <div class="search__text--input t-flex">
                    <mat-icon class="search-icon-light" svgIcon="search-icon-light" aria-hidden="false"
                        aria-label="search-icon-light"></mat-icon>
                    <input matInput autocomplete="off" placeholder="Speakers" value="" id="speaker-tag-input"
                        [(ngModel)]="langSearch" name="langSearch" (keyup)="onSearch()" />
                    <mat-icon class="close-circled" svgIcon="close-circled" aria-hidden="false"
                        aria-label="close-circled">
                    </mat-icon>
                </div>
            </mat-form-field>
        </div>
        <mat-list class="language--list" role="list">
            <mat-list-item *ngFor="let item of langulages"
                (click)="onSelectLang(item.value)">{{item.name}}</mat-list-item>
        </mat-list>
    </div>
    <div id="main" *ngIf="nav" class="flex-column">
        <a (click)="openNav($event)">
            <mat-icon class="hamburger" svgIcon="hamburger" aria-hidden="false" aria-label="hamburger"></mat-icon>
        </a>

        <a routerLink="/pages/dashboard" matTooltip="Dashboard" matTooltipPosition="right" matTooltipClass="custTooltip"
            *ngIf="appCommonService.isPermissionsExists(appCommonService.dashboardPermissions)"
            [class.active]="currentRoute.includes('/pages/dashboard') && !notification && !showUserMenu">
            <mat-icon class="home" svgIcon="home" aria-hidden="false" aria-label="home"></mat-icon>
        </a>
        <a [class.active]="currentRoute.includes('/pages/pma-projects') && !notification && !showUserMenu" matTooltip="Projects"
            matTooltipPosition="right" matTooltipClass="custTooltip"
            *ngIf="!appCommonService.isSuperAdmin()&&appCommonService.isPermissionsExists(appCommonService.pmaProjects)"
            routerLink="/pages/pma-projects">
            <mat-icon class="side-project" svgIcon="side-project" aria-hidden="false"
                aria-label="side-project"></mat-icon>
        </a>
        <a [class.active]="currentRoute.includes('/pages/admin-accounts') && !notification && !showUserMenu" matTooltip="Accounts"
            matTooltipPosition="right" matTooltipClass="custTooltip"
            *ngIf="appCommonService.isSuperAdmin() && appCommonService.isPermissionsExists(appCommonService.accountPermissions)"
            routerLink="/pages/admin-accounts">
            <mat-icon class="account" svgIcon="account" aria-hidden="false" aria-label="account"></mat-icon>
        </a>
        <a [class.active]="currentRoute.includes('/pages/admin-manage') && !notification && !showUserMenu" matTooltip="Manage"
            matTooltipPosition="right" matTooltipClass="custTooltip"
            *ngIf="appCommonService.isSuperAdmin() &&appCommonService.isPermissionsExists(appCommonService.adminManagePermissions)"
            routerLink="/pages/admin-manage">
            <mat-icon class="manage" svgIcon="manage" aria-hidden="false" aria-label="manage"></mat-icon>
        </a>
        <a [class.active]="currentRoute.includes('/pages/service-pricing') && !notification && !showUserMenu" matTooltip="Pricing"
            matTooltipPosition="right" matTooltipClass="custTooltip"
            *ngIf="appCommonService.isSuperAdmin() && appCommonService.isPermissionsExists(appCommonService.servicePricingPermissions)"
            routerLink="/pages/service-pricing">
            <mat-icon class="pricing" svgIcon="pricing" aria-hidden="false" aria-label="pricing"></mat-icon>
        </a>
        <a [class.active]="currentRoute.includes('/pages/billing') && !notification && !showUserMenu" matTooltip="Billing"
            matTooltipPosition="right" matTooltipClass="custTooltip"
            *ngIf="!appCommonService.isSuperAdmin() && appCommonService.isPermissionsExists(appCommonService.billingPermissions)"
            routerLink="/pages/billing">
            <mat-icon class="pricing" svgIcon="pricing" aria-hidden="false" aria-label="pricing"></mat-icon>
        </a>
        <a [class.active]="currentRoute.includes('/pages/files/') && !notification && !showUserMenu" matTooltip="Files"
            matTooltipPosition="right" matTooltipClass="custTooltip" routerLink="/pages/files/"
            *ngIf="appCommonService.isPermissionsExists(appCommonService.filesMenuPermissions)" (click)="onFilesClick()">
            <mat-icon class="video-file" svgIcon="video-file" aria-hidden="false" aria-label="video-file"></mat-icon>
        </a>
        <a [class.active]="currentRoute.includes('/pages/help') && !notification && !showUserMenu" matTooltip="Help"
            matTooltipPosition="right" matTooltipClass="custTooltip" routerLink="/pages/help">
            <mat-icon class="side-help" svgIcon="side-help" aria-hidden="false" aria-label="side-help"></mat-icon>
        </a>
        <a [class.active]="currentRoute.includes('/pages/settings/admin-settings') && !notification && !showUserMenu"
            matTooltipPosition="right" matTooltip="Settings" matTooltipClass="custTooltip"
            routerLink="/pages/settings/admin-settings">
            <mat-icon class="settings" svgIcon="settings" aria-hidden="false" aria-label="settings"></mat-icon>
        </a>
        <a [class.active]="notification && !showUserMenu" class="notification" matTooltipPosition="right" matTooltip="Notifications"
            matTooltipClass="custTooltip" *ngIf="appCommonService.isPermissionsExists(appCommonService.notifications)"
            (click)="bell($event)">

            <mat-icon class="bell" svgIcon="bell" aria-hidden="false" aria-label="bell"></mat-icon>
        </a>

        <a (click)="changeTheme()" matTooltip="Switch Theme" matTooltipPosition="right" matTooltipClass="custTooltip">
            <mat-icon class="theme-switch" svgIcon="theme-switch" aria-hidden="false" aria-label="theme-switch">
            </mat-icon>
        </a>
        <a [class.active]="!notification && showUserMenu" (click)="openNav($event)" matTooltip="User" matTooltipPosition="right" matTooltipClass="custTooltip">
            <mat-icon class="user-white" svgIcon="user-white" aria-hidden="false" aria-label="user-white"></mat-icon>
        </a>
        <!-- <a matTooltip="Select Language" matTooltipPosition="right" matTooltipClass="custTooltip">
            <mat-icon class="global-language" svgIcon="global-language" aria-hidden="false"
                aria-label="global-language"></mat-icon>
        </a> -->
    </div>
    <div *ngIf="notification && !showUserMenu" class="notify flex-column" [ngClass]="!nav?'notify-sidenav-margin':''" clickOutside
        (clickOutside)="clickedOutside()">

        <div class="flex-sap-bw flex-c pdl-16">
            <span>
                <h5>Notifications</h5>
            </span>
            <a routerLink="/pages/settings/admin-settings" (click)="notification=false">
                <mat-icon class="settings-white" svgIcon="settings-white" aria-hidden="false"
                    aria-label="settings-white"></mat-icon>
            </a>
        </div>
        <div class="warning--div flex-column">
            <span class="sticky"><img src="../../../../assets/images/new-right.svg" class="right-new"></span>
            <div class=" flex-sap-bw pb-0">
                <div class="t-flex colgap-8">
                    <span><img src="../../../../assets/images/warning-alert.svg"></span><span>
                        <h6 class="text-bold">Warning</h6>
                    </span>
                </div>

                <div>
                    <span class="font-9">2 pm</span>
                </div>
            </div>

            <div class="container" [class.show]="show">
                <h6>There is a warning on Media Conversion Service due to network problems. This is a possible critical
                    issue which needs attention. 5 jobs are delayed so far in the Media Conversion Service pipeline.
                </h6>
                <button class="top__right--btn-grey" mat-raised-button>Check Alert</button>
            </div>
            <span (click)="show = !show" style="cursor: pointer;color: var(--link-text2);">{{ show ? 'See less': 'See
                More' }}</span>

        </div>

        <div class="video--div flex-column">
            <span class="sticky"><img src="../../../../assets/images/new-right.svg" class="right-new"></span>
            <div class=" flex-sap-bw pb-0">
                <div class="t-flex colgap-8">
                    <span><img src="../../../../assets/images/small-video-file-blue.svg"></span><span>
                        <h6 class="text-bold">Output Available</h6>
                    </span>
                </div>

                <div>
                    <span class="font-9">09:43 AM, Jun 03 </span>
                </div>
            </div>
            <div>
                <h6> Output file is available to download for the job EP 13 - Good morning program with Karan with Job
                    Id : 36481</h6>
            </div>

        </div>
        <div class="media--div flex-column">
            <div class=" flex-sap-bw pb-0">
                <div class="t-flex colgap-8">
                    <span><img src="../../../../assets/images/small-video-file-grey.svg"></span><span>
                        <h6 class="text-bold">Media Uploaded</h6>
                    </span>
                </div>

                <div>
                    <span class="font-9">09:43 AM, Jun 03 </span>
                </div>
            </div>
            <div>
                <h6>Media uploaded successfuly for the job EP 13 - Good morning program with Karan with Job Id : 3648.
                </h6>
            </div>

        </div>
    </div>
</div>


<div class="enterprise_user--sidebar" *ngIf="currentRoute === '/pages/enterprise-files'">

    <div id="mySidenav" [@inOutAnimation]="!nav ? 'open' : 'closed'" class="sidenav flex-column">
        <div class="trance-icon">
            <span><a href="#"><img src="../../../../assets/images/  .svg" class="Trance" /></a></span>
            <span class="four-pointzero">4.4</span>
            <span> <a href="javascript:void(0)" class="closebtn" (click)="closeNav()"><img
                        src="../../../../assets/images/back.svg" class="back"></a></span>
        </div>
        <div class="sidevideo-filediv" routerLink="/pages/files/files" (click)="onFilesClick()"
            *ngIf="appCommonService.isPermissionsExists(appCommonService.filesMenuPermissions)">
            <span><a>
                    <mat-icon class="video-file" svgIcon="video-file" aria-hidden="false"
                        aria-label="video-file"></mat-icon>
                </a></span>
            <span class="sidevideo-filetext">Files</span>
        </div>
        <div class="sidevideo-filediv" routerLink="/pages/help">
            <span><a href="#">
                    <mat-icon class="side-help" svgIcon="side-help" aria-hidden="false"
                        aria-label="side-help"></mat-icon>
                </a></span>
            <span class="sidevideo-filetext">Help</span>
        </div>
        <div class="sidesettingsdiv">
            <span><a href="#">
                    <mat-icon class="settings" svgIcon="settings" aria-hidden="false" aria-label="settings"></mat-icon>
                </a></span>
            <span class="sidesettingstext">Settings</span>
        </div>
        <div class="sidenotificationsdiv " (click)="bell($event)"
            *ngIf="appCommonService.isPermissionsExists(appCommonService.notifications)">
            <span><a href="#">
                    <mat-icon class="bell" svgIcon="bell" aria-hidden="false" aria-label="bell"></mat-icon>
                </a></span>
            <span class="sidenotificationstext">Notifications</span>
        </div>
        <div class="sidetheme-switchdiv theme-toggle" (click)="changeTheme()">
            <span> <a (click)="changeTheme()">
                    <mat-icon class="theme-switch" svgIcon="theme-switch" aria-hidden="false" aria-label="switch">
                    </mat-icon>
                </a></span>
            <span class="sidetheme-switchtext">View in Light Theme</span>
        </div>
        <div class="sideaccount1div flex-column">
            <div class="side user-div flex-c">
                <span><a>
                        <mat-icon class="user-white" svgIcon="user-white" aria-hidden="false"
                            aria-label="user-white"></mat-icon>
                    </a></span>
                <span class="sideaccount1text" [title]="userName">{{userName}}</span>
            </div>
            <div class="pwd flex-c">
                <span><a>
                        <mat-icon class="password" svgIcon="password" aria-hidden="false"
                            aria-label="password"></mat-icon>
                    </a></span>
                <span class="pwdtext">Change Password</span>
            </div>
            <div class="exit flex-c" (click)="logout()">
                <span><a>
                        <mat-icon class="exit" svgIcon="exit" aria-hidden="false" aria-label="exit"></mat-icon>
                    </a></span>
                <span class="exittext">Log Out</span>
            </div>



        </div>
    </div>

    <!-- <div id="main" class="flex-column">
        <a (click)="openNav($event)" style="margin-bottom: 60px;">
            <mat-icon  class="hamburger" svgIcon="hamburger" aria-hidden="false"
                aria-label="Example thumbs up SVG icon"></mat-icon>
        </a>
    
    
        <a routerLink="/pages/files/files" (click)="onFilesClick()">
            <mat-icon  class="video-file" svgIcon="video-file" aria-hidden="false"
                aria-label="Example thumbs up SVG icon"></mat-icon>
        </a>
        <a href="#">
            <mat-icon  class="side-help" svgIcon="side-help" aria-hidden="false"
                aria-label="Example thumbs up SVG icon"></mat-icon>
        </a>
        <a routerLink="/pages/settings/admin-settings">
            <mat-icon  class="settings" svgIcon="settings" aria-hidden="false"
                aria-label="Example thumbs up SVG icon"></mat-icon>
        </a>
        <a style="margin-top: auto;">
    
            <mat-icon  class="bell" svgIcon="bell" aria-hidden="false"
                aria-label="Example thumbs up SVG icon" (click)="bell($event)"></mat-icon>
        </a>
    
        <a (click)="changeTheme()">
            <mat-icon  class="theme-switch" svgIcon="theme-switch" aria-hidden="false"
                aria-label="Example thumbs up SVG icon"> </mat-icon>
        </a>
        <a >
            <mat-icon (click)="openNav($event)" class="user-white" svgIcon="user-white" aria-hidden="false"
                aria-label="Example thumbs up SVG icon"></mat-icon>
        </a>
    </div> -->
    <div *ngIf="notification" class="notify flex-column">

        <div class="flex-sap-bw flex-c pdl-16">
            <span>
                <h5>Notifications</h5>
            </span>
            <mat-icon routerLink="/pages/settings/admin-settings" class="settings-white" svgIcon="settings-white"
                aria-hidden="false" aria-label="Example thumbs up SVG icon"></mat-icon>
        </div>
        <div class="warning--div flex-column">
            <span class="sticky"><img src="../../../../assets/images/new-right.svg" class="right-new"></span>
            <div class=" flex-sap-bw pb-0">
                <div class="t-flex colgap-8">
                    <span><img src="../../../../assets/images/warning-alert.svg"></span><span>
                        <h6 class="text-bold">Warning</h6>
                    </span>
                </div>

                <div>
                    <span class="font-9">2 pm</span>
                </div>
            </div>

            <div class="container" [class.show]="show">
                <h6>There is a warning on Media Conversion Service due to network problems. This is a possible critical
                    issue which needs attention. 5 jobs are delayed so far in the Media Conversion Service pipeline.
                </h6>
                <button class="top__right--btn-grey" mat-raised-button>Check Alert</button>
            </div>
            <span (click)="show = !show" style="cursor: pointer;color: var(--link-text2);">{{ show ? 'See less': 'See
                More' }}</span>

        </div>

        <div class="video--div flex-column">
            <span class="sticky"><img src="../../../../assets/images/new-right.svg" class="right-new"></span>
            <div class=" flex-sap-bw pb-0">
                <div class="t-flex colgap-8">
                    <span><img src="../../../../assets/images/small-video-file-blue.svg"></span><span>
                        <h6 class="text-bold">Output Available</h6>
                    </span>
                </div>

                <div>
                    <span class="font-9">09:43 AM, Jun 03 </span>
                </div>
            </div>
            <div>
                <h6> Output file is available to download for the job EP 13 - Good morning program with Karan with Job
                    Id : 36481</h6>
            </div>

        </div>
        <div class="media--div flex-column">
            <div class=" flex-sap-bw pb-0">
                <div class="t-flex colgap-8">
                    <span><img src="../../../../assets/images/small-video-file-grey.svg"></span><span>
                        <h6 class="text-bold">Media Uploaded</h6>
                    </span>
                </div>

                <div>
                    <span class="font-9">09:43 AM, Jun 03 </span>
                </div>
            </div>
            <div>
                <h6>Media uploaded successfuly for the job EP 13 - Good morning program with Karan with Job Id : 3648.
                </h6>
            </div>
        </div>
    </div>
</div>
